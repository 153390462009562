import gql from 'graphql-tag'
import { useState, useEffect } from 'react'

export const SUBGRAPH_HEALTH = gql`
  query health($name: Bytes) {
    indexingStatusForCurrentVersion(subgraphName: $name, subgraphError: allow) {
      synced
      health
      chains {
        chainHeadBlock {
          number
        }
        latestBlock {
          number
        }
      }
    }
  }
`

interface HealthResponse {
  data: {
    synced: boolean
    chain_head_block: string
    latest_block: string
  }
}

/**
 * Fetch top addresses by volume
 */
export function useFetchedSubgraphStatus(): {
  available: boolean | null
  syncedBlock: number | undefined
  headBlock: number | undefined
} {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [data, setData] = useState<HealthResponse | null>(null)

  useEffect(() => {
    async function fetchStatus() {
      try {
        // Mantle propietary subgraph service
        const response = await fetch('https://frax-subgraph-proxy-production.up.railway.app/subgraphs/name/ra-cl')
        setData(await response.json())
      } catch (error) {
        setError(error)
      }
      setLoading(false)
    }

    fetchStatus()
  }, [])

  if (loading) {
    return {
      available: null,
      syncedBlock: undefined,
      headBlock: undefined,
    }
  }

  if ((!loading && !data) || error) {
    return {
      available: false,
      syncedBlock: undefined,
      headBlock: undefined,
    }
  }

  const syncedBlock = data?.data.latest_block
  const headBlock = data?.data.chain_head_block

  return {
    available: true,
    syncedBlock: syncedBlock ? parseFloat(syncedBlock) : undefined,
    headBlock: headBlock ? parseFloat(headBlock) : undefined,
  }
}
